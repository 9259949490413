$(document).ready(function(){
	$('.menu .header').click(function(){
		$('.burger').toggleClass('open');
    $('.menu').toggleClass('open');
    $('.blackout').toggleClass('open');
	});

	$(this).scroll(function(){
		var offsetTop = $(this).scrollTop();

		if(Math.floor($('.cloud-4').offset().top) <= (offsetTop + $(window).height()) && $('.cloud-4').offset().top >= offsetTop){
			var offset = 5;
			offset += (offsetTop / 120);

			if(offset > 35){
				offset = 35;
			}

			$('.cloud-4').css('right', offset + '%');
		}

		if(Math.floor($('.cloud-3').offset().top) <= (offsetTop + $(window).height()) && $('.cloud-3').offset().top >= offsetTop){
			var offset = 50;
			offset -= (offsetTop / 60);

			if(offset < 15){
				offset = 15;
			}

			$('.cloud-3').css('right', offset + '%');
		}

		if(Math.floor($('.cloud-2').offset().top) <= (offsetTop + $(window).height()) && $('.cloud-2').offset().top >= offsetTop){
			var offset = 62;
			offset -= (offsetTop / 240);

			if(offset > 75){
				offset = 75;
			}

			$('.cloud-2').css('right', offset + '%');
		}

		if(Math.floor($('.cloud-1').offset().top) <= (offsetTop + $(window).height()) && $('.cloud-1').offset().top >= offsetTop){
			var offset = 82;
			offset -= (offsetTop / 140);

			if(offset < 50){
				offset = 50;
			}

			$('.cloud-1').css('right', offset + '%');
		}

		if(Math.floor($('.readometer').offset().top) <= (offsetTop + $(window).height()) && $('.readometer').offset().top >= offsetTop){
			var offset = -25;
			offset += (offsetTop / 35);

			if(offset >= 0){
				offset = 0;
			}

			$('.readometer').css('right', offset + '%');
		}

		if(Math.floor($('.fact-sheet-left-1').offset().top) <= (offsetTop + $(window).height()) && $('.fact-sheet-left-1').offset().top >= offsetTop){
			var offset = -300;
			offset += (offsetTop / 4.5);

			if(offset >= 0){
				offset = 0;
			}

			$('.fact-sheet-left-1').css('margin-left', offset + '%');
		}

		if(Math.floor($('.fact-sheet-left-2').offset().top) <= (offsetTop + $(window).height()) && $('.fact-sheet-left-2').offset().top >= offsetTop){
			var offset = -300;
			offset += (offsetTop / 10);

			if(offset >= 0){
				offset = 0;
			}

			$('.fact-sheet-left-2').css('margin-left', offset + '%');
		}

		if(Math.floor($('.fact-sheet-right-1').offset().top) <= (offsetTop + $(window).height()) && $('.fact-sheet-right-1').offset().top >= offsetTop){
			var offset = -300;
			offset += (offsetTop / 4.5);

			if(offset >= 0){
				offset = 0;
			}

			$('.fact-sheet-right-1').css('margin-right', offset + '%');
		}

		if(Math.floor($('.fact-sheet-right-2').offset().top) <= (offsetTop + $(window).height()) && $('.fact-sheet-right-2').offset().top >= offsetTop){
			var offset = -250;
			offset += (offsetTop / 10);

			if(offset >= -100){
				offset = -100;
			}

			$('.fact-sheet-right-2').css('margin-right', offset + '%');
		}

		if(Math.floor($('.fact-sheet-right-3').offset().top) <= (offsetTop + $(window).height()) && $('.fact-sheet-right-3').offset().top >= offsetTop){
			var offset = -200;
			offset += (offsetTop / 10);

			if(offset >= 0){
				offset = 0;
			}

			$('.fact-sheet-right-3').css('margin-right', offset + '%');
		}

		if(Math.floor($('.video-bubble').offset().top) <= (offsetTop + $(window).height()) && $('.video-bubble').offset().top >= offsetTop){
			var offset = 0.5;
			offset += (offsetTop / 2500);

			if(offset >= 1){
				offset = 1;
			}

			$('.video-bubble').css('transform', 'scale(' + offset + ')');
		}

		if(Math.floor($('.scientist').offset().top) <= (offsetTop + $(window).height() + 500) & $('.scientist').offset().top >= offsetTop){
			var offset = -50;
			offset += (offsetTop / 55);

			if(offset >= -4){
				offset = -4;
			}

			$('.scientist').css('bottom', offset + '%');
		}
	});
});

$('#myModal').on('show.bs.modal', function (event) {
  var button = $(event.relatedTarget);
  var videolink = button.data('videolink');
  var modal = $(this);
	var html = '<iframe src="' + videolink + '" width="100%" height="600" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>';
  modal.find('.modal-body').html(html);
});
